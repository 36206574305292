/* eslint eqeqeq: "off" */
import React, { useEffect } from "react"
import Layout from "../ui/Layout"
import connectStore from "../state/connectStore"
import { getOrderToppings, getToppingDatas, isToppingSelected, isToppingExtra, fetchAvailableIngredientCounts, isAvailable, isExtraAvailable } from "../util/OrderUtil"
import ToppingCard from "../ui/ToppingCard"
import {
  Button,
  Card,
  Divider,
} from "semantic-ui-react"
import Color from "../style/Color"

const Footer = ({ toppings, removeTopping, onNext, genericLabel }) => (
  <>
    <Button onClick={onNext} color={Color.accent} icon="angle right" labelPosition="right" size="huge"
      content={
        toppings.length == 0 ? "No " + genericLabel + "s" : (toppings.length == 1 ? toppings.length + " " + genericLabel : toppings.length + " " + genericLabel + "s")
      }
    />
  </>
);

const View = ({ location, title, order, availableIngredientCounts, setAvailableIngredientCounts, setDryToppings, setWetToppings, addDryTopping, addWetTopping, toggleDryTopping, toggleWetTopping, removeTopping, toppingType, modal=false, allowExtra=false, onNext, to }) => {
  const toppings = getOrderToppings(order, toppingType);
  const genericLabel = toppingType == "dry" ? "Topping" : "Drizzle";
  const _toggleTopping = toppingType == "dry" ? toggleDryTopping : toggleWetTopping;
  const _addTopping = toppingType == "dry" ? addDryTopping : addWetTopping;
  const setToppings = toppingType == "dry" ? setDryToppings : setWetToppings;

  let toggleTopping = _toggleTopping;
  let addTopping = _addTopping;
  if(modal) {
    toggleTopping = (d, m) => {
      _toggleTopping(d, m);
      onNext();
    };
    addTopping = (d, m) => {
      _addTopping(d, m);
      onNext();
    };
  }

  useEffect(() => {
    fetchAvailableIngredientCounts(setAvailableIngredientCounts);
  }, [setAvailableIngredientCounts]);

  return (
    <Layout location={location} to={to} header={title} showBag footer={
      <Footer toppings={modal ? [] : toppings} removeTopping={removeTopping} onNext={modal ? () => {
        setToppings([]);
        onNext();
      } : onNext} genericLabel={genericLabel}/>
    }>
      <Card.Group centered>
      {getToppingDatas(toppingType).map((toppingData, i) => (
        <ToppingCard
          key={i}
          toppingData={toppingData}
          selected={isToppingSelected(toppings, toppingData)}
          extra={isToppingExtra(toppings, toppingData)}
          onAdd={addTopping}
          onToggle={toggleTopping}
          modal={modal}
          allowExtra={allowExtra && isExtraAvailable(availableIngredientCounts, toppingType + "Toppings", toppingData.name)}
          available={isAvailable(availableIngredientCounts, toppingType + "Toppings", toppingData.name)}
        />
      ))}
      </Card.Group>
      <Divider hidden/>
      <Divider hidden/>
      <Divider hidden/>
    </Layout>
  );
};

export default connectStore(View);


// An example of using cards with borders
/*<Card.Content>
  <Icon.Group>
    <Image
      circular
      size="small"
      src={image}
      style={toppings[value] ? { border: "3px solid " + Color.accentStyle } : null}
    />
    {toppings[value] && <Icon name="check" color={Color.accent} size="huge"/>}
  </Icon.Group>
</Card.Content>
<Card.Content extra>
  <Card.Header>
    <Header as="h3">
      <Icon
        name={toppings[value] ? "check square outline" : "square outline"}
        color={Color.accent}
        size="tiny"
      />
      {label}
    </Header>
  </Card.Header>
  {description}
</Card.Content>*/

import React from "react"
import {
  Icon,
  Image,
  Card,
  Header,
  Label,
} from "semantic-ui-react"
import { getImage } from "../util/OrderUtil"
import Color from "../style/Color"

const noShadowStyle = {
  boxShadow: "none",
};

const imageCheckedStyle = {
  border: "0.5em solid " + Color.accentStyle,
  boxShadow: "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px, rgba(0, 0, 0, 0.5) 0px 0px 10px 5px inset",
};

const imageUncheckedStyle = {};

const headerStyle = {
  display: "inline",
};

const extraHeaderStyle = {
  display: "inline",
  marginLeft: "1em",
};

const ToppingCard = ({ toppingData, selected, extra, selectable=true, available=true, onAdd=null, onToggle=null, modal=false, allowExtra=false, size="small" }) => {
  const { name, label, description } = toppingData;
  const image = getImage(name);

  let icon;
  if(modal) {
    icon = selected ? "dot circle outline" : "circle outline";
  } else {
    icon = selected ? "check square outline" : "square outline";
  }

  const onClickToSelect = () => onToggle({ name, extra }, modal);
  const onClickToExtra = () => onAdd({ name, extra: !extra }, modal);

  return (<>
    <Card
      centered
      link
      style={noShadowStyle}
    >
      <Card.Content>
        <Icon.Group
          onClick={available ? onClickToSelect : null}
        >
          <Image
            circular
            size={size}
            style={!modal && selected ? imageCheckedStyle : imageUncheckedStyle }
            disabled={!available}
          >
            {image}
          </Image>
          {!modal && selected && false && <Icon name="check" color={Color.accent} size="huge"/>}
        </Icon.Group>
      </Card.Content>
        <Card.Header>
          <Header
            as="h3"
            style={headerStyle}
            onClick={available ? onClickToSelect : null}
          >
            { !modal && available && 
              <Icon
                name={icon}
                color={Color.accent}
                size="tiny"
              />
            } 
            {!available && <><Label basic color="red" size="big">Out of Stock!</Label><br/></>}
            {label}
          </Header>
          {!modal && allowExtra && selected &&
            <Header
              as="h3"
              style={extraHeaderStyle}
              onClick={onClickToExtra}
            >
              {available && 
                <>
                  <Icon
                    name={extra ? "check square outline" : "square outline"}
                    color={Color.accent}
                    size="tiny"
                  />
                  Extra
                </>
              }
            </Header>
          }
        </Card.Header>
        <Card.Meta>{description}</Card.Meta>
    </Card>
  </>);
};

export default ToppingCard;
